import React, { useContext, useEffect, useState } from "react"
import { validate as uuidValidate } from "uuid"
import { PublicProfileResponse } from "../../services/api_types"
import { ApiContext, UserContext } from "../user_wrapper"
import ExpertProfile from "../expert_profile"

const Profile: React.FC = props => {
  const [profile, setProfile] = useState<PublicProfileResponse | undefined>(
    undefined
  )
  const user = useContext(UserContext)
  const apiClient = useContext(ApiContext)

  const profileUseEffect = async () => {
    if (typeof window !== "undefined") {
      const splitHref = window.location.href.split("/")
      const indexOfProfile = splitHref.findIndex(
        element => element === "profile"
      )
      if (indexOfProfile === splitHref.length - 1) {
        alert("no profile ID")
        return
      }

      const profileId = splitHref[indexOfProfile + 1]

      if (!uuidValidate(profileId)) {
        alert(`invalid profile id: ${profileId}`)
        return
      }

      const res = await apiClient.getPublicProfile(profileId)

      if (!!res.error) {
        alert(`issue loading profile for id: ${profileId}`)
      }

      setProfile(res.data)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      profileUseEffect()
    }
  }, [])

  return <ExpertProfile publicProfile={profile} />
}

export default Profile
